import { Injectable } from '@angular/core';
import {Http } from '@angular/http';
import { Observable} from 'rxjs';
import { baseUrl } from '../constants/baseurl';
import{ tap,map } from 'rxjs/operators';
import * as glob from '../constants/baseurl'
@Injectable({
  providedIn: 'root'
})
export class DeveloperService {

  constructor(private http: Http) { }

  getDevelopers(): Observable<any>{
    return this.http.get(baseUrl+'developers').pipe((map (data => data.json())))
  } 
  addDevelopers(data): Observable<any>{
    return this.http.post(baseUrl+'developers',data).pipe()
  }
  getDevelopersById(id): Observable<any>{
    return this.http.get(baseUrl+'developers/'+[id]).pipe((map (data => data.json())))
  }  
  editDevelopers(id,data): Observable<any>{
    return this.http.patch(baseUrl+'developers/'+id,data).pipe((map (data => data.json())))
  } 
  getAllCountry():Observable<any>
  {
    return this.http.get(baseUrl+'countries').pipe((map (data => data.json())))
  }
  getAllStates(countryId):Observable<any>
  {
    return this.http.get(baseUrl+'countries/'+countryId+'/states').pipe((map (data => data.json())))
  }
}
