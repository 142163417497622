import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { baseUrl } from '../constants/baseurl';
import{ tap,map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(private http: HttpClient) { }

  getUserGroup(): Observable<any>{
    return this.http.get(baseUrl+'userGroups?groupType=Internal User').pipe();
  }
  getUserGroupOfSuperAdmin(): Observable<any>{
    return this.http.get(baseUrl+'userGroups?groupType=Super Admin').pipe();
  }
  getTaskFrequencies(): Observable<any>{
    return this.http.get(baseUrl+'taskFrequencies').pipe();
  }
  createTask(data,id): Observable<any>{
    return this.http.post(baseUrl+'clients/'+id+'/tasks',data).pipe(); 
  }
  createSelfTask(community_id,client_id,data): Observable<any>{
    return this.http.post(baseUrl+'communities/'+community_id+'/clients/'+client_id+'/taskSchedules',data).pipe(); 
  }
  getAllCreatedTasks(id): Observable<any>{
    return this.http.get(baseUrl+'clients/'+id+'/tasks').pipe();
  }
  getAllCreatedTasksById(id): Observable<any>{
    return this.http.get(baseUrl+'taskSchedules/'+id).pipe();
  }
  getUnassignedTasks(id,year) : Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/years/'+year+'/tasks?assigned=false');
  }
  getAssignedTasks(id,year) : Observable<any>{
    return this.http.get(baseUrl+'communities/'+id+'/years/'+year+'/tasks?assigned=true');
  }
  createTaskSchedule(community_id,client_id,year,data): Observable<any>{
    return this.http.post(baseUrl+'communities/'+community_id+'/clients/'+client_id+'/years/'+year+'/taskSchedules',data).pipe();
  }
  updateTaskSchedule(client_id,id,data): Observable<any>{
    return this.http.patch(baseUrl+'clients/'+client_id+'/taskSchedules/'+id,data).pipe();
  }
  getNewAssignedTasksWithDate(community_id,client_id,usergroup_id,start_date,end_date): Observable<any>{
    return this.http.get(baseUrl+'communities/'+community_id+'/clients/'+client_id+'/userGroups/'+usergroup_id+'/taskSchedules?startDate='+start_date+'&endDate='+end_date).pipe();
  }
  getAssignedToUserTasksWithDate(community_id,client_id,usergroup_id,start_date,end_date): Observable<any>{
    return this.http.get(baseUrl+'communities/'+community_id+'/clients/'+client_id+'/userGroups/'+usergroup_id+'/taskSchedules?assignedToUser=true&startDate='+start_date+'&endDate='+end_date).pipe();
  }
  getAlreadyAssignedTasksWithDate(community_id,client_id,usergroup_id,start_date,end_date): Observable<any>{
    return this.http.get(baseUrl+'communities/'+community_id+'/clients/'+client_id+'/userGroups/'+usergroup_id+'/taskSchedules?assignedToUser=false&startDate='+start_date+'&endDate='+end_date).pipe();
  }
  getScheduledByMeTasksWithDate(community_id,start_date,end_date): Observable<any>{
    return this.http.get(baseUrl+'communities/'+community_id+'/taskSchedules?isSuperAdmin=true&startDate='+start_date+'&endDate='+end_date).pipe();
  }
  getOtherTasksWithDate(community_id,start_date,end_date): Observable<any>{
    return this.http.get(baseUrl+'communities/'+community_id+'/taskSchedules?isSuperAdmin=false&startDate='+start_date+'&endDate='+end_date).pipe();
  }
  getCreatedByMeTasksWithDate(community_id,client_id,start_date,end_date): Observable<any>{
    return this.http.get(baseUrl+'communities/'+community_id+'/clients/'+client_id+'/taskSchedules?startDate='+start_date+'&endDate='+end_date).pipe();
  }
  AssignTasksToMe(client_id,id,data): Observable<any>{
    return this.http.patch(baseUrl+'clients/'+client_id+'/taskSchedules/'+id+'?action=Assign',data).pipe();
  }
  getInformedToTasks(community_id,client_id,userGroupId,start_date,end_date): Observable<any>{
    return this.http.get(baseUrl+'communities/'+community_id+'/clients/'+client_id+'/userGroups/'+userGroupId+'/taskSchedules?informedTo=true&startDate='+start_date+'&endDate='+end_date).pipe();
  }
  ReleaseTask(client_id,id,data): Observable<any>{
    return this.http.patch(baseUrl+'clients/'+client_id+'/taskSchedules/'+id+'?action=Release',data).pipe();
  }

}

