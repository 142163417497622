import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as glob from '../../constants/baseurl'
@Injectable({
  providedIn: 'root'
})
export class ServiceProviderService {

  constructor(private _http : HttpClient) { }

  getServiceDocumentsOnService(serviceId){
    return this._http.get(glob.baseUrl+'services/'+serviceId+'/documents').pipe();
  }
  getCommonDocumentsForSp(){
    return this._http.get(glob.baseUrl+'services/commonDocuments').pipe();
  }

  addServiceProvider(userData){
    return this._http.post(glob.baseUrl+'register',userData).pipe()
  }
  getAllCountries(){
    return this._http.get(glob.baseUrl+'countries').pipe()
  }
  getStateOnCountry(countryId){
    return this._http.get(glob.baseUrl+'countries/'+countryId+'/states').pipe()

  }

  getAllService(){
    
    return this._http.get(glob.baseUrl+'services').pipe()
  }
  getCommunitybyClientID(id){
    return this._http.get(glob.baseUrl+'clients/'+[id]+'/communities').pipe();
  }
  getServiceCategory(){
    return this._http.get(glob.baseUrl+'servicecategories?groupType=Internal User').pipe();
  }
  getServicesList(id){
    return this._http.get(glob.baseUrl+'servicecategories/'+[id]+'/services').pipe();
  }
  getServiceProviderbyService(communityId,serviceId){
    return this._http.get(glob.baseUrl+'communities/'+communityId+'/services/'+serviceId+'/nonAssignedServiceProviders').pipe();
  }
  getServiceProviderByCommunity(clientId,communityId,serviceId){
    return this._http.get(glob.baseUrl+'clients/'+clientId+'/communities/'+communityId+'/services/'+serviceId).pipe();
  }
  saveServiceProviderCommunity(clientId,communityId,serviceId,data){
    return this._http.post(glob.baseUrl+'clients/'+clientId+'/communities/'+communityId+'/services/'+serviceId,data).pipe();
  }
  getAllCommunity(){
    return this._http.get(glob.baseUrl+'communities').pipe();
  }
}
