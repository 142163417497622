import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { baseUrl } from '../constants/baseurl';
import{ tap,map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class SpService {

  constructor(private http: HttpClient) { }
  getSP(id): Observable<any>{
    return this.http.get (baseUrl+'serviceProviders').pipe()
  } 
  getSPDetail(id){
    return this.http.get(baseUrl+'clients/'+[id]).pipe()
  } 

  patchSPDetail(clientid,spId,data){
    return this.http.patch(baseUrl+'clients/'+clientid+'/spServices/'+spId,data).pipe();
  }
  createSP(data){
    return this.http.post(baseUrl+'register',data).pipe()
  }
  patchVerifyDoc(clientid,docId,data){
    return this.http.patch(baseUrl+'clients/'+clientid+'/spServiceDocuments/'+docId,data).pipe();
  }
  getSPStaffListing(clientId):Observable<any>
  {
    return this.http.get(baseUrl+'clients/'+clientId+'/serviceProviderStaffs').pipe()
  }
  getSpDocs(spId) {
    return this.http.get(baseUrl+'clients/'+spId+'/spDocuments').pipe();
  }
  uploadSpDocs(spId,docs) {
    return this.http.post(baseUrl+'clients/'+spId+'/spDocuments',docs).pipe();
  }
  updateSpCoomonDoc(spId,docs) {
    return this.http.post(baseUrl+'clients/'+spId+'/spServiceDocuments',docs).pipe();
  }
}

